import { FetchRequest } from '@rails/request.js';

export const fetchGet = async (url, params = {}) => {
  const request = new FetchRequest(
    'get',
    `${url}/?${Object.keys(params)
      .map(k => `${k}=${params[k]}`)
      .join('&')}`,
    { responseKind: 'json' }
  );

  const response = await request.perform();

  if (response.ok) {
    return await response.text;
  }

  return response.statusCode;
};

export const fetchPut = async (action, url, body = {}) => {
  let payload = {
    responseKind: 'json',
    body: JSON.stringify(body),
  };
  const request = new FetchRequest(action, url, payload);
  const response = await request.perform();

  if (response.ok) {
    return await response.text;
  }

  return response.statusCode;
};

export const fetchDelete = async (url, id) => {
  const request = new FetchRequest('delete', `${url}/${id}`, {
    responseKind: 'json',
  });

  const response = await request.perform();

  if (response.ok) {
    return await response.text;
  }

  return response.statusCode;
};

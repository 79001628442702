import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['spacer', 'header', 'button', 'magicLinkForm'];

  handleScroll() {
    const scrollPosition = window.scrollY;

    if (scrollPosition > 400) {
      this.headerTarget.classList.add('scan-header-fixed');
      this.spacerTarget.classList.add('header-spacer-large');
    } else {
      this.headerTarget.classList.remove('scan-header-fixed');
      this.spacerTarget.classList.remove('header-spacer-large');
    }
  }

  handleScrollTo(event) {
    let buttonTarget = event.target;
    while (buttonTarget.classList.contains('magic-link-button') === false) {
      buttonTarget = buttonTarget.parentNode;
    }
    let magicLinkFormPosition = this.magicLinkFormTarget.offsetTop;
    let parent = this.magicLinkFormTarget.offsetParent;
    while (parent) {
      magicLinkFormPosition += parent.offsetTop;
      parent = parent.offsetParent;
    }

    if (window.innerWidth > 991) {
      magicLinkFormPosition += 120;
    }

    window.scrollTo({
      top: magicLinkFormPosition,
      behavior: 'smooth',
    });
  }
}

import { Controller } from '@hotwired/stimulus';
import { fetchPut } from '../plugins/api';

export default class extends Controller {
  static targets = ['card', 'spinner', 'submitButton'];

  static values = { subscriberId: Number };

  connect() {
    this.selectedItems = [];
  }

  handleSelect(event) {
    let { target } = event;

    while (!target.classList.contains('sm-card-manga-vertical')) {
      target = target.parentNode;
    }

    if (
      this.selectedItems.length >= 3 &&
      !target.classList.contains('flipped')
    ) {
      target.classList.add('shake');
      setTimeout(() => {
        target.classList.remove('shake');
      }, 400);
      return;
    }

    if (target.classList.contains('flipped')) {
      target.classList.remove('flipped');
      this.selectedItems = this.selectedItems.filter(
        item => item !== target.id
      );
    } else {
      target.classList.add('flipped');
      this.selectedItems.push(target.id);
    }

    if (this.selectedItems.length >= 3) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  async onSubmit() {
    this.spinnerTarget.classList.remove('hidden-spinner');
    const { redirection_url: redirectionUrl } = JSON.parse(
      await fetchPut(
        'post',
        `/subscribers/${this.subscriberIdValue}/update_recommendations`,
        {
          manga_ids: this.selectedItems,
        }
      )
    );

    if (redirectionUrl) {
      setTimeout(() => {
        window.location.href = redirectionUrl;
      }, 3000);
    }
  }
}

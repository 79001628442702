function displayNone (element){
  element.style.display = "none"
}

function clearAlert(){
  if (document.querySelector(".notice")) {
    var notice = document.querySelector(".notice");
    notice.classList.toggle("slide-up");
  }
}

export { clearAlert }

function closeFlash(){
  if ( document.querySelector(".close-button-box") ) {
    const closeButtons = document.querySelectorAll(".close-button-box");
    closeButtons.forEach((closeButton) => {
      closeButton.addEventListener("click", (event) => {
        var flash = closeButton.parentNode;
        flash.style.display = "none";
      });
    });
  }
}

export { closeFlash }

function localeRedirection(){
  if (document.querySelector(".locale-flash")) {
    // Closing flash when user clicks on the close button
    document.querySelector(".switch-locale-button").addEventListener("click", (event) => {
      var form = document.getElementById("languages");
      var locale = form.value;
      var rootUrl = window.location.href.split("?locale=")[0];
      if (rootUrl.includes("?")) {
        window.location.href = rootUrl + "&locale=" + locale;
      } else {
        window.location.href = rootUrl + "?locale=" + locale;
      }
    });
  }
}

export { localeRedirection }
